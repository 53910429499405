import { DynamicAttributeType } from '@futureecom/futureecom-js/dist/services/setting-service'
import type { Rules } from '@/types/validator'

export const staticRules: Partial<Record<DynamicAttributeType, Rules>> = {
  [DynamicAttributeType.STRING]: ['string'],
  [DynamicAttributeType.NUMERIC]: ['numeric'],
  [DynamicAttributeType.TEXT]: ['string'],
  [DynamicAttributeType.BOOLEAN]: ['boolean']
}

export const nestedRules: Partial<Record<DynamicAttributeType, Rules>> = {
  [DynamicAttributeType.SELECT_MULTIPLE]: ['string_or_num'],
  [DynamicAttributeType.COMBOBOX]: ['string_or_num'],
  [DynamicAttributeType.CHECKBOX]: ['string_or_num'],
  [DynamicAttributeType.MONEY]: ['array']
}

export const dynamicAttributeRules: Partial<Record<DynamicAttributeType, Rules>> = { ...staticRules, ...nestedRules }
