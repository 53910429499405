import { DynamicAttributeType } from '@futureecom/futureecom-js/dist/services/setting-service'
import type {
  DynamicAttribute,
  NewDynamicSelectAttribute
} from '@futureecom/futureecom-js/dist/services/setting-service'

export const typesWithOptions = [
  DynamicAttributeType.SELECT,
  DynamicAttributeType.COMBOBOX,
  DynamicAttributeType.SELECT_MULTIPLE,
  DynamicAttributeType.CHECKBOX
]

export const typesWithRequiredOptions = typesWithOptions.filter((item) => item !== DynamicAttributeType.COMBOBOX)

export const hasOptions = (val?: Partial<DynamicAttribute>): val is NewDynamicSelectAttribute => {
  return Boolean(val?.type && typesWithOptions.includes(val.type))
}
