import {
  DynamicAttribute,
  DynamicAttributeConfigurationSluggable,
  DynamicAttributeType
} from '@futureecom/futureecom-js/dist/services/setting-service'
import type { DynamicAttributeConfigurationKeys } from '@/types/settings/DynamicAttributes'

const getConfigurationOptions = (
  ...configs: DynamicAttributeConfigurationKeys[]
): DynamicAttribute['configuration'] => {
  const allConfigs: Record<
    DynamicAttributeConfigurationKeys,
    boolean | number | null | DynamicAttributeConfigurationSluggable
  > = {
    readonly: false,
    searchable: 0,
    filterable: false,
    sortable: false,
    translatable: false,
    html: false,
    json: false,
    sluggable: { source: '', separator: '-' },
    visibility: null
  }

  return configs.reduce((prev, current) => {
    return current in allConfigs ? { ...prev, [current]: allConfigs[current] } : prev
  }, {})
}

export const typeConfigurationOptions: Record<DynamicAttribute['type'], DynamicAttribute['configuration']> = {
  [DynamicAttributeType.STRING]: getConfigurationOptions(
    'readonly',
    'searchable',
    'filterable',
    'sortable',
    'translatable',
    'sluggable',
    'visibility'
  ),
  [DynamicAttributeType.TEXT]: getConfigurationOptions(
    'readonly',
    'searchable',
    'filterable',
    'sortable',
    'translatable',
    'html',
    'json',
    'visibility'
  ),

  [DynamicAttributeType.NUMERIC]: getConfigurationOptions('readonly', 'filterable', 'sortable', 'visibility'),
  [DynamicAttributeType.BOOLEAN]: getConfigurationOptions('readonly', 'filterable', 'sortable', 'visibility'),
  [DynamicAttributeType.MONEY]: getConfigurationOptions('readonly', 'filterable', 'sortable', 'visibility'),
  [DynamicAttributeType.SELECT]: getConfigurationOptions(
    'readonly',
    'searchable',
    'filterable',
    'translatable',
    'visibility'
  ),
  [DynamicAttributeType.SELECT_MULTIPLE]: getConfigurationOptions(
    'readonly',
    'searchable',
    'filterable',
    'translatable',
    'visibility'
  ),
  [DynamicAttributeType.COMBOBOX]: getConfigurationOptions(
    'readonly',
    'searchable',
    'filterable',
    'translatable',
    'visibility'
  ),
  [DynamicAttributeType.CHECKBOX]: getConfigurationOptions(
    'readonly',
    'searchable',
    'filterable',
    'translatable',
    'visibility'
  ),
  [DynamicAttributeType.LINKED]: getConfigurationOptions('readonly', 'filterable', 'visibility')
}
