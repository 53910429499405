import { DynamicAttributeType } from '@futureecom/futureecom-js/dist/services/setting-service'
import { isObject } from '../../../../objects'
import type { DynamicAttribute } from '@futureecom/futureecom-js/dist/services/setting-service'
import type { Rules } from '@/types/validator'

export const typeValidations: Record<DynamicAttributeType, Rules> = {
  [DynamicAttributeType.STRING]: ['string'],
  [DynamicAttributeType.TEXT]: ['string'],
  [DynamicAttributeType.NUMERIC]: ['numeric'],
  [DynamicAttributeType.MONEY]: [],
  [DynamicAttributeType.BOOLEAN]: ['boolean'],
  [DynamicAttributeType.COMBOBOX]: ['array'],
  [DynamicAttributeType.SELECT]: ['array'],
  [DynamicAttributeType.SELECT_MULTIPLE]: ['array'],
  [DynamicAttributeType.CHECKBOX]: ['array'],
  [DynamicAttributeType.LINKED]: []
}

export const getValidations = (validation: DynamicAttribute['validation']): string[] => {
  return (isObject(validation) ? validation['0'] : validation) || []
}
