import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_vm._t("activator",null,null,{ attrs: attrs, on: on })]}}],null,true),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.saveHandler.apply(null, arguments)}}},[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_c('span',[(!_vm.value)?_c('span',[_vm._v(_vm._s(_vm.$t('Add option')))]):_c('span',[_vm._v(_vm._s(_vm.$t('Update option')))])]),_c(VIcon,{on:{"click":function($event){_vm.isOpen = false}}},[_vm._v("close")])],1),_c(VDivider),_c(VCardText,{staticClass:"d-flex flex-gap-32"},[_c('dynamic-attribute-option-text',{attrs:{"error-messages":_vm.errors['text']},on:{"input":_vm.onOptionChange},model:{value:(_vm.option),callback:function ($$v) {_vm.option=$$v},expression:"option"}}),_c('dynamic-attribute-option-value',{attrs:{"error-messages":_vm.errors['value']},on:{"input":_vm.onOptionChange},model:{value:(_vm.option),callback:function ($$v) {_vm.option=$$v},expression:"option"}})],1),_c(VDivider),_c(VCardActions,{staticClass:"justify-end pa-4"},[_c(VBtn,{attrs:{"depressed":"","color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }