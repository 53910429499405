import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-gap-8 flex-column"},[_c(VCombobox,_vm._b({attrs:{"multiple":"","label":_vm.$t('Validation rules'),"outlined":"","items":_vm.suggestedRules,"hide-details":"auto","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c(VChip,[_c('span',{staticClass:"d-flex flex-gap-8 align-center"},[_c('span',[_vm._v(_vm._s(item))]),(_vm.isDeletable(item).value)?[_c(VDivider,{attrs:{"vertical":""}}),_c(VIcon,{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("close")])]:_vm._e()],2)])]}}]),model:{value:(_vm.validation),callback:function ($$v) {_vm.validation=$$v},expression:"validation"}},'v-combobox',_vm.$attrs,false)),_c('external-link',{staticClass:"text-caption",attrs:{"value":"laravel.com/docs/9.x/validation#available-validation-rules","label":_vm.$t('List of available rules')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }