import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('expandable-content',{attrs:{"outlined":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"d-flex flex-column",class:{ 'error--text': _vm.errors['options'] }},[_c('span',[_vm._v(_vm._s(_vm.$t('Options'))+" ("+_vm._s(_vm.options.length)+")")]),(_vm.errors['options'])?_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.errors['options']))]):_vm._e()])]},proxy:true},{key:"content",fn:function(){return [_c(VDivider),_c('div',{staticClass:"pa-4"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',{staticClass:"grey--text text--darken-2"},[_vm._v(_vm._s(_vm.$t('Add option')))]),_c('dynamic-attribute-option-form',{on:{"apply":_vm.addOption},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"depressed":"","small":"","color":"primary"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("add")])],1)]}}])})],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.options,"item-index":"index"},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('dynamic-attribute-option-form',{attrs:{"value":item},on:{"apply":function($event){return _vm.updateOption(item.index, $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('button-action',_vm._g(_vm._b({attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit')}},'button-action',attrs,false),on))]}}],null,true)})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('delete-button',_vm._b({attrs:{"suppress-confirmation":""},on:{"click":function($event){return _vm.removeOption(item.index)}}},'delete-button',{ icon: true },false))]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }